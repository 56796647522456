import DataComponentBase from '@/commons/DataComponentBase';
import possibilityDialog from "./PossibilityDialog.vue";
import { PossibilityHandlerEntity } from './PossibilityHandlerEntity';


export default {
    name: "PossibilityHandler",

    extends: DataComponentBase,

    components: {
        possibilityDialog,
    },

    data() {
        return {
            dialog: {
                list: true,
                open: false,
                edit: false,
                delete: false
            },
            headerFilters: {
                customer: "",
                loc_city: "",
                loc_site_size: "",
                financing_budget: "",
                created: "",
                remember_datetime_1: "",
                status: "",
                actions: ""
            },

            headers: [
                {
                    text: this.$i18n.t("common.uploaded"),
                    value: "created",
                    align: "left"
                },
                {
                    text: this.$i18n.t("common.customer"),
                    value: "customer",
                    align: "left"
                },
                {
                    text: this.$i18n.t("common.location"),
                    value: "loc_city",
                    align: "left"
                },
                {
                    text: this.$i18n.t("common.loc_site_size_short"),
                    value: "loc_site_size",
                    align: "right"
                },
                {
                    text: this.$i18n.t("common.financing_budget"),
                    value: "financing_budget",
                    align: "right"
                },
                {
                    text: this.$i18n.t("common.status"),
                    value: "status",
                    align: "right"
                },
                {
                    text: this.$i18n.t("common.remember_datetime"),
                    value: "remember_datetime_1",
                    align: "right"
                },
                {
                    text: "",
                    value: "actions",
                    sortable: false
                },
            ],

            entityHandler: new PossibilityHandlerEntity(this.$log, this.$store),
            needRoles: null,
            serviceTypeId: 0,
            defaultDataTableTotalItems: 0,
            customPagination: {
                sortBy: ["remember_datetime_1"],
                sortDesc: [true],
                rowsPerPage: -1,
                totalItems: this.defaultDataTableTotalItems,
                rowsPerPageText: this.$i18n.t("common.rowsPerPageText"),

            },
            footerProps: {
                itemsPerPageOptions: [5, 10, 25, 50, 100],
                'items-per-page-text': this.$t("common.rowsPerPageText")
            },
            statuses: []
        }
    },
    created() {
        this.entityHandler.init()
            .then(response => {
                this.statuses = response.data.data["status"];
                this.statuses.map(el => {
                    el.value = this.$i18n.t("common.possibility.status." + el.key);
                })
            })
            .catch(error => {
                this.$log.error(error);
            })
            .finally(() => { });
    },
    computed: {
        filteredItems() {
            return this._filteredItems();
        },
        computedDatatableHeight(){
            return this.styleProps.windowHeight - 200;
        }
    },
    methods: {
        _filteredItems() {
            if (this.loadedEntities) {
                var filteredList = this.loadedEntities.filter(item => {
                    let filterKeys = this.headers.map(item => item.value);
                    for (let idx in filterKeys) {
                        let filterKey = filterKeys[idx];

                        if (this.headerFilters[filterKey] !== null && this.headerFilters[filterKey] != "") {
                            if (filterKey == "status") {
                                if (item[filterKey].toString().toLocaleUpperCase().indexOf(this.headerFilters[filterKey].toString().toLocaleUpperCase()) === -1) {
                                    return false;
                                }
                            } else {
                                if (item[filterKey].toString().toLocaleUpperCase().indexOf(this.headerFilters[filterKey].toString().toLocaleUpperCase()) === -1) {
                                    return false;
                                }
                            }
                        }
                    }
                    return true;
                })
                return filteredList
            }
        },
        closeDialog() {
            this.dialog.list = true;
            this.dialog.edit = false;
            this.dialog.open = false;
            this.dialog.delete = false;

            //this.$emit('input', this.value)
        },
        callbackUpdateEntity(entity) {
            this.selectedEntity = entity;
        }
    },
    watch: {
        loadedEntities() {
            this.defaultDataTableTotalItems = this.loadedEntities.length;
        }
    },

};
