// import Authentication from '@/commons/Authentication';


export default {
    data() {
        return {
            dialog: {
                open: false,
                edit: false,
                delete: false,
                details: false
            },
            //   snackbar: false,
            selectedEntity: {},

            defaultDataTableTotalItems: 0,
            customPagination: {
                sortBy: 'name',
                descending: false,
                rowsPerPage: -1,
                totalItems: this.defaultDataTableTotalItems
            },
            CURRENT_MENU: "",
            entityHandler: null,
            allowedButtons: [],

            styleProps: {
                windowHeight: window.innerHeight,
            }
        };
    },

    created() {
        this.fetchEntities();
    },

    computed: {
        loadedEntities() {
            return this.entityHandler.entityItems;
        },
    },

    mounted() {
        window.addEventListener('resize', () => {
            this.styleProps.windowHeight = window.innerHeight;
        });
    },

    methods: {
        fetchEntities() {
            if (this.entityHandler) {
                this.entityHandler.fetchEntities();
            }
        },
        // closeDialog() {
        //   this.dialog.open = false;
        //   this.dialog.edit = false;
        //   this.dialog.delete = false;
        //   this.clearDialog();
        // },
        clearDialog() {

        },
        openNewDialog() {
            this.clearDialog();
            this.selectedEntity = this.entityHandler.createEntityObj();
            this.dialog.edit = false;
            this.dialog.open = true;
        },
        openEditDialog(entity) {
            this.dialog.edit = true;
            this.dialog.open = true;
            this.selectedEntity = entity;
        },
        openDeleteDialog(entity) {
            this.dialog.delete = true;
            this.dialog.details = false;
            this.dialog.open = false;
            this.selectedEntity = entity;
        },
        deleteSelectedEntity() {
            this.$log.debug('DataComponentBase of DataComponentBase - deleteSelectedEntity');
            this.$log.debug(this.selectedEntity);

            this.entityHandler
                .deleteEntity(this.selectedEntity)
                .then((response) => {
                    this.$store.commit('app/setStatusCode', response.status);
                    this.$store.commit('app/setSnackbarEnabled', true);
                })
                .catch(error => {
                    this.$log.error(error);
                    this.$store.commit('app/setStatusCode', error.getCode());
                })
                .finally(() => {
                    this.closeDialog();
                });
        },
        createEntityObj() { },
    },

    mounted() {

    },

    watch: {
        loadedEntities() {
            this.defaultDataTableTotalItems = this.loadedEntities.length;
        }
    },
};
